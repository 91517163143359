@import '/src/ressources/styles/abstracts';

.price-pool-link {
    text-align: center;
    font-size: 1.2rem;
    font-weight: 500;
    color: $primary;

    i.icon {
        margin-right: $small;
    }

    a {
        text-decoration: none;
        color: $primary;
    }
}
@import '/src/ressources/styles/abstracts';

.edit-sponsors-modal {
    .sponsor.selected {
        background-color: $selectedbg !important;
        i.icon {
            color: $positive;
            font-size:1.4rem;
            line-height: 1.4rem;
        }
    }
}
@import '/src/ressources/styles/abstracts';

.sponsor-modal {
    .form {
        .website-input {
            display: grid;
            grid-template-columns: auto 1fr;

            .https {
                padding: 0 $small;
                border:2px solid $secondary;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                border-right: none;
                display:flex;
                align-items: center;
                background-color: $secondary;
                color: $white;
                font-weight: 400;
            }

            input, input:focus {
                flex-grow: 1;
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
            }
        }
    }
}
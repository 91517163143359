@import '/src/ressources/styles/abstracts';

.sponsors {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    gap:$medium;
    .sponsor-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        border:1px solid $secondary;

        .sponsor-img-wrapper {

        }

        button {
            position: absolute;
            bottom:$tiny;
            right: $tiny;
            margin:0;
            line-height: 1.2rem;
        }

        
    }

    .ui.button.secondary {
        width: 100%;
        aspect-ratio: 5/2;
        border-radius: 0px;
        background-color: $white;
        border: 1px solid $secondary;
        color: $secondary;

        &:hover {
            color: $white;
        }
    }
}

@include respond-to('desktop-small-max') {
    .sponsors {
        .sponsor-wrapper {
            .sponsor-img-wrapper {
                min-height: 60px;
            }
        }
    }
}

@include respond-to('tablet-max') {
    .sponsors {
        grid-template-columns: repeat(2, 1fr);
    }
}

@include respond-to('medium-max') {
    .sponsors {
        grid-template-columns: 1fr;
    }
}
@import '/src/ressources/styles/abstracts';

.card {
    background-color: $secondary;
    display: flex;
    flex-direction: column;
    padding: $medium;
    border-radius: 10px;
    color: $white;
    aspect-ratio: 8 / 5;
    max-height: 300px;

    .card-header {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: auto;

        .card-corner-text {
            justify-self: left;
            font-size: 1.4rem;
            font-weight: 500;
        }

        .card-sponsor {
          height: 36px;
          border-radius: 5px;
          background-color: white;
          justify-self: center;
          padding: 5px
        }

        .card-icon {
            justify-self: right;
            font-size:1.5rem;
            i.icon {
                line-height: 1.5rem;
            }
        }
    }

    .card-center-icon {
        flex-grow: 1.5;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        align-items: center;

        i.icon {
            font-size: 3rem;
            line-height: 3rem;
        }
    }

    .card-title {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-bottom: $small;
        h3 {
            font-size: 1.2rem;
            font-weight: 500;
            text-align: center;
            color: $white;
        }
    }

    .card-button {
        text-align: right;
    }

    &.image {
        background-size: cover;
        position: relative;

        &::before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.65);
            overflow: hidden;
            border-radius: 10px;
            z-index: 1;
        }

        .card-header,
        .card-footer,
        .card-title {
            z-index: 5;
        }
    }
}

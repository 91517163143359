@import '/src/ressources/styles/abstracts';

.livenow {
    width: 22px;
    height: 22px;
    display: inline-block;
    margin-left: $medium;

> div {
    vertical-align: middle;
    width: 22px;
    height: 22px;
    border-radius: 100%;
    position: absolute;
    margin: 0 auto;
    border:3px solid rgba($primary,1);
    -webkit-animation: live 1.4s infinite ease-in-out;
    animation: live 1.4s infinite ease-in-out;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    
    &:nth-child(1) {
        background-color:rgba($primary,0.3);
        background-color:rgba($primary,1);
        -webkit-animation-delay: -0.1s;
        animation-delay: -0.1s;
    }

    &:nth-child(2) {
        -webkit-animation-delay: 0.16s;
        animation-delay: 0.16s;
    }

    &:nth-child(3) {
        -webkit-animation-delay: 0.42s;
        animation-delay: 0.42s;
        border:3px solid rgba($primary,0.5);
    }

    &:nth-child(4) {
        border:3px solid rgba($primary,1);
        -webkit-animation-delay: -0.42s;
        animation-delay: -0.42s;
    }
}
}
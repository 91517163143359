@import '/src/ressources/styles/abstracts';

.slider {
    width: 100%;
    border-radius: 50px;
    border: 1px solid $gray_dark;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 58px;
    z-index: 1;
    @include transition(background-color, 0.6s, ease);

    .slider-latch {
        position: absolute;
        left: $tiny;
        top: 50%;
        transform: translateY(-50%);
        @include transition(left, 0.6s, ease);
        background-color: $appbg;
        border-radius: 100%;

        i.icon {
            margin: 0;
            font-size: 50px;
            width: 50px;
            line-height: 50px;
            color: $secondary;
            @include transition(color, 0.6s, ease);
        }

        &.sliding {
            @include transition(none);
            i.icon {
                color: $secondary_effect;
            }
        }
    }

    .slider-label {
        font-size: 1.5rem;
        color: $gray_semidark;
        font-weight: 500;
        pointer-events: none;
        user-select: none;
        z-index: -1;
    }

    &.locked {
        pointer-events: none;
        background-color: rgba(#495057, 0.2);

        .slider-latch {
            background-color: transparent;
            i.icon {
                color: $gray_semidark;
            }
        }
    }
}

@import '/src/ressources/styles/abstracts';

#admin-header {
    .admin-header-bg {
        width: 100%;
        height: 110px;
        background-image: url('../../ressources/img/header_bg2.png');
        background-size: 100% cover;
        background-repeat: no-repeat;
        background-position: bottom center;
    }

    nav {
        ul {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(5, 1fr);

            li {
                text-align: center;
                background-color: $primary;
                padding: $small 0;
                color: $white;
                font-size: 1.3rem;
                font-weight: 500;
                @include transition(background-color, 0.4s, ease);
            }

            li.active,
            li:hover,
            li:focus {
                background-color: $primary_effect;
                cursor: pointer;
            }

            li.bars {
                display: none;
            }
        }
    }
    i.icon {
        color: $secondary;
        padding: 16px;
        &.question {
          font-size: 2rem;
          line-height: 2rem;
          text-decoration: none;
        }
      }
}

#admin-header.responsive {
    nav {
        ul {
            grid-template-columns: 1fr;
            li {
                display: none;
            }
            li.bars {
                display: block;
                text-align: right;
                padding-right: $medium;
            }
        }
    }

    &.open {
        nav {
            ul {
                li:not(.bars) {
                    display: block;
                    text-align: left;
                    padding-left: $medium;
                }
            }
        }
    }
}

@import '/src/ressources/styles/abstracts';

.challenge-sponsor {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;

    & > *:not(:last-child) {
        margin-bottom: $small;
    }

    span {
        text-decoration: none !important;
    }
}

@import '/src/ressources/styles/abstracts';

#game-settings-details {
    margin-bottom: $medium;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .game-settings-header {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        margin-bottom: $large;

        .game-description {
            max-width: 300px;
            form .field input, form.ui.form .field input  {
                text-align: center;
            }
    
            .ui.form {
                .field {
                    display: flex;
    
                    input {
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                    }
                    button {
                        border-radius: 0;
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px;
                        border:2px solid $gray-dark;
                        border-left: none;
                    }
                }
            }
        }

        .game-state-actions {
            display: flex;
            justify-content: flex-end;
        }
    }

    .game-settings-setup-actions {
        display: flex;
        justify-content: center;
        margin-bottom: $medium;

        & > *:not(:last-child) {
            margin-right: $small;
        }
    }

    .challenge-applications-list {
        flex-grow: 1;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        border-collapse: collapse;

        .challenge-applications-state {
            border-right: 2px solid $gray_dark;

            &:nth-child(1) {
                border-left: 2px solid $gray_dark;
            }

            h2 {
                margin-bottom: $small;
                i.icon {
                    margin-right: $small;
                }
            }

            .challenge-applications {
                display: flex;
                flex-direction: column;
                margin: 0 $small;

                & > *:not(:last-child) {
                    margin-bottom: $small;
                }

                .challenge-application {

                    .challenge-application-name {
                        p {
                            color: $white;
                            text-align: center;
                            margin-bottom: $tiny;
                        }
                    }
                }
            }
        }
    }
    
   

    .game-settings-title {
        display: flex;
        justify-content: center;
        align-items: center;

        h1 {
            margin-bottom: 0;
        }
}

@-webkit-keyframes live {
    0%, 80%, 100% { -webkit-transform: scale(0.6) }
    40% { -webkit-transform: scale(1.0) }
}

@keyframes live {
    0%, 80%, 100% { 
        transform: scale(0.6);
        -webkit-transform: scale(0.6);
    } 40% { 
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
        }
    }
}

@include respond-to('desktop-small-max') {
    #game-settings-details {
        .game-settings-header {
            display: flex;
            flex-wrap: wrap;

            .game-settings-title {
                order: 1;
                width: 100%;
                margin-bottom: $medium;
            }

            .game-description {
                order:2;
                width: 50%;
                max-width: 100%;

                form {
                    max-width: 300px;
                }
            }

            .game-state-actions {
                order:3;
                width: 50%;
            }
        }


        .challenge-applications-list {
            grid-template-columns: 1fr 1fr;

            .challenge-applications-state {
                margin-top:$large;

                &:nth-child(3) {
                    border-left:2px solid $gray_dark;
                }
            }
        }
    }
}

@include respond-to('tablet-max') {
    #game-settings-details .game-settings-setup-actions {
        display: grid;
        grid-template-columns: 1fr 1fr;

        button {
            margin: 0 0 $small 0 !important;
        }
    }
}

@include respond-to('very-large-max') {
    #game-settings-details .challenge-applications-list {
        grid-template-columns: 1fr;
        border-left: 2px solid $gray_dark;
        border-right: 2px solid $gray_dark;

        .challenge-applications-state, .challenge-applications-state:nth-child(1), .challenge-applications-state:nth-child(3) {
            border:none;
        }
    }

    #game-settings-details .game-settings-setup-actions {
        grid-template-columns: 1fr;
    }

    #game-settings-details {
        .game-settings-header {
            .game-description {
                width: 100%;
                order:3;
                form {
                    width: 100%;
                    margin:0;
                    max-width: 100%;

                    input {
                        width: 100%;
                    }
                }
            }

            .game-state-actions {
                width: 100%;
                order:2;
                justify-content: center;
                margin-bottom: $medium;
            }
        }
    }
}
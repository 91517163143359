.ui.modal.list-modal {
    .list-modal-item {
        display: flex;
        justify-content: space-between;
        padding: $medium $small;
        border-bottom: 1px solid $secondary;
        align-items: center;

        &:hover {
            cursor: pointer;
            background-color: rgba($secondary_effect, 0.1);
        }

        &:first-child {
            border-top: 1px solid $secondary;
        }

        .list-modal-item-right {
            display: flex;
            align-items: center;
            & > *:not(:last-child) {
                margin-right: $medium;
            }
        }
    }

    .actions {
        button {
            margin-left:$medium;
        }
    }
}

.ui.modal.image-upload-modal {
    .content {
        display: flex;
        gap:$medium;

        .form {
            flex-grow: 1;
        }

        .image-upload {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
        }
    }
}

.ui.page.modals.dimmer {
    .information-modal {
        .content {
            text-align: center;
            padding-bottom: $small;

            h2 {
                font-size: 32px;
                margin-bottom: 16px;
                color: $white;
            }

            p {
                font-size: 20px;
                color:$white;
                margin-bottom: $medium;
            }
        }

        .actions {
            text-align: center !important;
        }
    }
}
.ui.popup {
    &.error-popup {
        background-color: rgba($negative, 1);
        border:none;
        border-radius: 32px;

        &.admin {
            position: absolute !important;
            top:calc(100vh - 270px);
            left:50%;
            transform: translateX(-50%);
            display: block;
        }

        &::before {
            border:none;
            background-color: transparent;
            box-shadow: none;
        }

        .content {
            color:$white;
            white-space: nowrap;
        }
    }
}
@import '/src/ressources/styles/abstracts';

.dropZone {
    width: 300px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .placeholder, .img-preview-wrapper {
        border:2px solid $secondary;
        border-radius: 5px;
        cursor:pointer;
    }

    .placeholder {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex-grow: 1;

        p {
            color: $gray;
        }
        
        i.icon {
            color:$gray;
            font-size: 1.5rem;
            line-height: 1.5rem;
        }

        & > *:not(:last-child) {
            margin-bottom: $small;
        }
    }

    .img-preview-wrapper {
        aspect-ratio: 5/2;
        min-height: 80px;
        margin-bottom: $small;
    }
}
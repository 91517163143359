@import '/src/ressources/styles/abstracts';

.player {
    display: flex;

    .player-image {
        min-width: 80px;
        width: 80px;
        background-color: $gray;
        aspect-ratio: 4 / 5;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;

        img {
            width: 100%;
        }
    }

    .player-info {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-left: $medium;
        text-align: left;
        flex-grow: 1;

        .player-select-number {
            font-size: 2.5rem;
            line-height: 2.5rem;
            color: $gray_semidark;
            font-weight: 500;
        }

        .player-select-name {
            color: $secondary;
            font-size: 1.4rem;
            font-weight: 600;
        }
    }

    .player-icon {
        display:flex;
        justify-content: center;
        align-items: center;

        i.icon {
            color: $secondary;
            font-size: 1.5rem;
            line-height: 1.5rem;
            margin-right: $small;

            &.check {
                color:$positive;
            }
        }
    }
    
    &.selected {
        background-color: $selectedbg !important;
    }
}

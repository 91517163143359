@import '/src/ressources/styles/abstracts';

header.user-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: $small $small;

    & > i.icon {
        font-size: 2rem;
        line-height: 2rem;
        color: $secondary;
        cursor: pointer;
    }
}

.home-layout {
    header {
        i.icon {
            color: $white;
            &.question {
                font-size: 2rem;
                line-height: 2rem;
                text-decoration: none;
            }
        }
        i.home {
            display: none;
        }
    }
}

.app-theme {
    header.user-header {
        border-bottom: 2px solid transparent;
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2);
        @include transition(box-shadow, 0.2s, ease);
        &.shadow {
            box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.2);
        }
    }
}

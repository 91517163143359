@import '/src/ressources/styles/abstracts';

#page-not-found {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
        margin-bottom: $medium;
    }
}
@import '/src/ressources/styles/abstracts';

.player-modal {
    .image-upload {
        .dropZone {
            width: 200px;
        }
        .img-preview-wrapper {
            width: 80px;
            aspect-ratio: 80 / 105;
            box-sizing: content-box;
            margin: $medium auto;
            border-radius: 0px;
            border:none;
        }
    }
}
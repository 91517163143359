@import '/src/ressources/styles/abstracts';

.reward-settings-rewards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: $medium;

    & > .card, .add-reward {
        height: 100%;
    }

    .card {
        cursor: pointer;
    }

    & > .card:not(:last-child), .add-reward {
        margin-right: $medium;
        margin-bottom: $medium;
    }

    .placeholder-text {
        grid-column: 1/-1;
    }

    .add-reward {
        aspect-ratio: 8/5;
        border: 2px solid $secondary;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    
        cursor: pointer;
        @include transition(background-color, 0.5s, ease);

        i.icon,
        p {
            @include transition(color, 0.5s, ease);
        }

        i.icon {
            font-size: 1.2rem;
            line-height: 1.2rem;
            margin-right: $small;
        }

        p {
            font-weight: 500;
        }

        &:hover {
            background-color: $secondary;
            i.icon,
            p {
                color: $white;
            }
        }
    }
}

@include respond-to('desktop-small-max') {
    .reward-settings-rewards {
        grid-template-columns: repeat(3, 1fr);
    }
}

@include respond-to('tablet-max') {
    .reward-settings-rewards {
        grid-template-columns: repeat(2, 1fr);
    }
}

@include respond-to('medium-max') {
    .reward-settings-rewards {
        grid-template-columns: 1fr;
    }
}
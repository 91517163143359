@import '/src/ressources/styles/abstracts';

#challenge {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-grow: 1;
    align-items: center;

    & > *:not(:last-child) {
        margin-bottom: $small;
    }

    .caption {
        color: $gray_semidark;
    }

    h1 {
        font-size: 1.4rem;
        line-height: 1.4rem;
        color:$secondary;
    }

    .player-select {
        margin-bottom: $small;
    }
}
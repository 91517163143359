@import '../abstracts/colors';

// :root {
//     --amplify-primary-color: #a71417 !important;
//     --amplify-primary-tint: #790f11 !important;
//     --amplify-primary-shade: #1e2746 !important;
//     --amplify-background-color: #e5e5e5 !important;
//     --amplify-components-button-primary-background-color: #1e2746 !important;
//     --amplify-components-button-primary-hover-border-color: #21337e !important;
//     --amplify-components-button-primary-hover-background-color: #21337e !important;
// }
[data-amplify-authenticator] {
    background-color: transparent !important;
    width: 100% !important;
    max-width: 100% !important;
    .amplify-button[data-variation='primary'] {
        background-color: $secondary !important;
        &:hover {
            background-color: $secondary_effect !important;
        }
    }

    [data-amplify-footer] {
        display: none;
    }

    [data-amplify-container] {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
            max-width: 60%;
            margin-bottom: $medium;
        }
    }
}

@import '/src/ressources/styles/abstracts';

.ui.modal.confirmation-modal {
    .content {
        h3 {
            color: $primary;
            font-size: 1.4rem;
        }
    }
}

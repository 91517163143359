@import 'abstracts/colors';
@import 'abstracts/mixins';
@import 'abstracts/variables';

@import 'base/reset';
@import 'base/typography';

@import 'components/buttons';
@import 'components/form';
@import 'components/amplify';
@import 'components/modals';
@import 'components/popups';

html {
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
}

body {
    margin: 0;
    background-color: $appbg;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);

}

#root {
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    width: 100%;
    display: flex;
    flex-flow: column;
}

#content {
    & > .loader {
        width: 100%;
    }
}

@import '/src/ressources/styles/abstracts';

.modal-result-tip-divider {
  display: inline-block;
  align-self: flex-end;
  padding: .67857143em 0.5em;
  font-size: 1em;
  text-align: center;
  font-weight: 400;
}

.left, .left > .input > input {
  text-align: right;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

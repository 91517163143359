@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    font-size: 16px;
}

body {
    font-family: $font !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 0.95em;
    font-weight: 400;
    line-height: 1.5;
    color: #111111;
}

h1 {
    font-size: 2rem;
    color: $gray_dark;
    font-weight: 600;
    text-align: center;
    line-height: 2.2rem;
}

h2 {
    font-size: 1.2rem;
    font-weight: 600;
    color: $gray_semidark;
    text-align: center;
}

h2.divider, p.divider {
    color: $gray_dark;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before,
    &::after {
        content: '';
        display: block;
        background-color: $gray_dark;
        height: 2px;
        width: 100%;
    }

    &::before {
        background: linear-gradient(to right, rgba(240, 240, 240, 0), 5px, $gray_dark);
        margin-right: $medium;
    }

    &::after {
        background: linear-gradient(to left, rgba(240, 240, 240, 0), 5px, $gray_dark);
        margin-left: $medium;
    }
}

h3 {
    color: $secondary;
    font-size: 1.1rem;
    font-weight: 600;
}

p {
    font-size: 1rem;
    color: $gray_dark;
}

ol {
    list-style: decimal;
    padding-left: $medium;

    li {
        margin-bottom: $small;
    }
}

a, .link {
    color: $secondary_effect;
    text-decoration: underline;

    @include transition(color, 0.4s, ease);
    &:hover,
    &:focus,
    &:active {
        color: $primary_effect;
    }

    &.button {
        text-decoration: none;
    }
}

.negative {
    color:$primary;
}

.positive {
    color:$positive;
}

.partialPositive {
    color:$orange
}

strong {
    font-weight: 500;
}

p.placeholder-text {
    text-align: center;
    color: $gray_semidark;
    font-weight: 500;
    margin: $small 0;
    width: 100%;
}

.text-content {
    h1 {
        margin-bottom: $medium;
    }

    h2 {
        text-align: left;
    }

    p, ul, ol {
        margin-bottom: $medium;
    }
}

.center {
    text-align: center !important;
}

@include respond-to('tiny') {
    :root {
        font-size: 14px;
    }
}




form,
form.ui.form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    & > *:not(:last-child) {
          margin-bottom: $small !important;
    }

    .field {
        margin:0;
        label {
            color: $gray_dark;
        }

        ::placeholder {
            color:$gray;
        }
        :-ms-input-placeholder {
            color: $gray;
        }

        ::-ms-input-placeholder {
            color: $gray;
        }

        input, div[role="combobox"] {
            font-family: inherit;
            color: $gray_dark;
            border: 2px solid $secondary;
            @include transition(border-color, 0.4s, ease);

            &:focus {
                border-color: $secondary_effect;
            }

            &:disabled {
                opacity: 1;

            }
        }

        .input {
            &.disabled {
                ::placeholder {
                    color:$gray_dark;
                }
                :-ms-input-placeholder {
                    color: $gray_dark;
                }

                ::-ms-input-placeholder {
                    color: $gray_dark;
                }
                opacity: 0.5;
                color: $gray_dark;
            }
        }
    }
}

@include respond-to('tiny') {
    form, form.ui.form {
        gap:$small;
    }
}

@import '/src/ressources/styles/abstracts';

#user-settings {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        margin-bottom: $medium;
    }

    & > button {
        margin-bottom: $large; 
    }

    .input {
        margin-bottom: $small;
    }
}
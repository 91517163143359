@import '/src/ressources/styles/abstracts';

.app-theme {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    flex-grow: 1;

    header {
        position: fixed;
        top: 0px;
        background-color: $appbg;
        z-index: 10;
        width: 100%;
    }

    #content {
        padding: 55px $small 100px;
        max-width: 1280px;
        width: 100%;
        margin:0 auto;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    #footer-nav {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 10;
        width: 100%;
    }
}

@include respond-to('small') {
    .app-theme #content {
        padding-right: $medium;
        padding-left: $medium;
    }
}
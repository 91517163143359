@import 'variables';

@mixin transition($what: all, $time: 0.2s, $how: ease-in-out) {
    -webkit-transition: $what $time $how;
    -moz-transition: $what $time $how;
    -ms-transition: $what $time $how;
    -o-transition: $what $time $how;
    transition: $what $time $how;
}

@mixin respond-to($breakpoint) {
    $raw-query: map-get($breakpoints, $breakpoint);

    @if $raw-query {
        $query: if(type-of($raw-query) == 'string', unquote($raw-query), inspect($raw-query));

        @media #{$query} {
            @content;
        }
    } @else {
        @error 'No value found for `#{$breakpoint}`. '
           + 'Please make sure it is defined in `$breakpoints` map.';
    }
}

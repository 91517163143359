@import '/src/ressources/styles/abstracts';

.squad {
    .players-divider {
        margin-top: $medium;
        margin-bottom: $small;
    }
    .players {
        display:grid;
        grid-template-columns: repeat(3, 1fr);
        gap:$small;
        margin-bottom: $medium;

        .player {
            border:2px solid $gray_dark;
            padding:$tiny;
            @include transition (background-color, 0.5s, ease);
        }

        .player:hover {
            background-color: rgba($secondary_effect, 0.2);
        }
    }
}

@include respond-to('tablet-max') {
    .squad .players {
        grid-template-columns: 1fr 1fr;
    }
}

@include respond-to('very-large-max') {
    .squad .players {
        grid-template-columns: 1fr;
    }
}

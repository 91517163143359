@import '/src/ressources/styles/abstracts';

.authenticator{
    width: 100%;
    align-self: center;
    max-width: 500px;

    .divider {
        margin: $medium 0;
    }

    .authenticator-social-providers {
        .social-provider {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: $small;
            padding:$small 0;
            border: 1px solid $gray_semidark;
            cursor: pointer;

            img {
                max-width: 30px;
                margin-right: $medium;
            }
        }
    }
}

.mail-sent-confirmation {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    i.icon {
        font-size: 3rem;
        line-height: 3rem;
        color:$secondary;
        margin-bottom: $medium;
    }
}

@include respond-to('tiny') {
    .authenticator .authenticator-social-providers .social-provider {
        padding:$tiny 0;
        img {
            max-width: 25px;
        }
    }
}
@import '/src/ressources/styles/abstracts';

.player-placeholder {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;

    .player-placeholder-image {
        width: 25%;
        max-width: 80px;
        background-color: $gray;
        aspect-ratio: 4 / 5;
        min-height: 64px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;

        i.icon {
            font-size: 2rem;
            line-height: 2rem;
            margin: 0;
        }
    }

    .player-placeholder-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: $medium;
        text-align: left;
        flex-grow: 1;

        .player-placeholder-text {
            font-size: 1.4rem;
            color: $gray_semidark;
            font-weight: 500;
        }
    }

  .player-icon {
    display: flex;
    justify-content: center;
    align-items: center;

    i.icon {
      font-size: 1.5rem;
      line-height: 1.5rem;
      color: $secondary;
      margin-right: $small;

      &.check {
        color: $positive;
      }
    }

  }

  &.selected {
    background-color: $selectedbg !important;
  }
}

@import '/src/ressources/styles/abstracts';

.img-preview-wrapper,
.sponsor-img-wrapper {
    width: 100%;
    min-height: 80px;
    aspect-ratio: 5/2;
    max-width: 300px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    cursor: pointer;
}

@import '/src/ressources/styles/abstracts';
.challenges {
    form {
        width: 500px;
        display: flex;
        flex-direction: column;
    }
    
    .challenge {
        padding: $medium;
        background-color: $secondary;
        border-radius: $small;
        margin-bottom: $tiny;
        display: flex;
        color: $white;
        align-items: center;
        cursor: pointer;
        @include transition(background-color, 0.4s, ease);
    
        .challenge-question {
            flex-grow: 1;
        }
    
        .challenge-actions {
            button {
                margin-left: $small;
            }
        }
    
        &:hover {
            background-color: $secondary_effect;
        }
    
        &.challenge-create {
            border: 2px solid $secondary;
            background-color: transparent;
            color: $secondary;
            font-weight: 500;
            display: flex;
            flex-direction: row-reverse;
            justify-content: center;
            line-height: 1rem;
            font-size: 1rem;
            @include transition(all, 0.4s, ease);

            i.icon {
                margin-right: $small;
            }
    
            &:hover,
            &:focus,
            &:active {
                background-color: $secondary_effect;
                color: $white;
            }
        }
    }
}

@include respond-to('tablet-max') {
    .challenges {
        .challenge {
            flex-direction: column;

            .challenge-question {
                margin-bottom: $small;
                text-align: center;
            }
        }
    }
}
@import '/src/ressources/styles/abstracts';

.user-selection {
  width: 100%;
}

.main-select {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: $small;
  border: 1px solid $gray;
  border-radius: $small;
  display: flex;
  align-items: center;
  @include transition(all, 0.4s, ease);

  &.active:hover {
    border-color: $primary;
    background-color: rgba($secondary, 0.1);
    cursor: pointer;
  }
}

.footer-select-actions {
  display: flex;
  flex-direction: column;
  margin:0 auto;
  max-width: 400px;

  a {
    margin-bottom: $small;
  }
}

.select-footer {
  color: $gray_semidark
}

@include respond-to('tiny') {
  .main-select {
    padding:$tiny;
  }
}

@import '/src/ressources/styles/abstracts';

.setup-players-modal {
    .players {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap:$small;
        margin:$medium 0;

        .player {
            border:2px solid $gray_dark;
            padding:$tiny;
            @include transition (background-color, 0.5s, ease);
    
            &:hover {
                background-color: rgba($secondary_effect, 0.2);
            }
        }
    }
}
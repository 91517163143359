@import '/src/ressources/styles/abstracts';

.admin-theme {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    #content {
        max-width: 1280px;
        width: 100%;
        margin: $large auto;
        padding: 0 $medium;
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        h1 {
            margin-bottom: $large;
        }
    }

    
}

.admin-login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: $medium;
    flex-grow: 1;

    img {
        width: 250px;
    }

    .ui.form {
        width:100%;
        max-width: 400px;
    }
}
@import '/src/ressources/styles/abstracts';

nav#footer-nav {
    display: grid;
    grid-template-columns: 1fr 1.5fr 1fr;
    grid-template-rows: 20px auto;
    grid-auto-rows: 50px;

    .nav-item {
        padding: $small;
        text-align: center;
        color: $white;
        grid-row: 2 / 3;
        z-index: 3;
        font-size: 1rem;
        position: relative;

        .notification-counter {
            position: absolute;
            top:$small;
            left:calc(50% + 28px);
            transform: translateX(-50%);
            background-color: $primary;
            min-width: 24px;
            height: 24px;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            p {
                color: $white;
            }
        }

        i.icon {
            display: block;
            margin: 0 auto 6px;
            font-size: 1.2rem;
        }
    }

    .nav-item-left {
        grid-column: 1 / 2;
    }

    .nav-item-center {
        background-color: $primary;
        border-radius: 100px;
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.2);

        i.icon {
            font-size: 1.5rem;
        }
    }

    .nav-item-right {
        grid-column: 3 / -1;
    }

    .nav-bg {
        grid-column: 1 / -1;
        grid-row: 2 / 3;
        background-color: $secondary;
        z-index: 2;
        box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.2);
    }
}

@include respond-to('tiny') {
    nav#footer-nav {
        grid-template-rows: 10px auto;
         .nav-item {
            padding:4px;
         }
    }
}
@import '/src/ressources/styles/abstracts';

.home-layout {
    #content {
        display: flex;
        flex-direction: column-reverse;
    }
}

.home-content {
    text-align: center;
    display: flex;
    flex-direction: column;

    h1 {
        color: $white;
        font-size: 1.6rem;
        font-weight: 600;
        margin-bottom: $medium;
    }

    img {
        margin: 0 auto;
        max-width: 60%;
    }
}

@import '/src/ressources/styles/abstracts';

.player {
    flex-grow: 1;
}

> i.icon {
    font-size: 1.5rem;
    line-height: 1.5rem;
    color:$secondary;
    margin-right: $small;

    &.check {
        color: $positive;
    }

    &.close {
        color: $primary;
    }
}

@include respond-to('tiny') {
    .player {
        .player-image {
            min-width: 60px;
            width: 60px;
        }
        .player-info {
            .player-select-number {
                font-size: 2rem;
            }
            .player-select-name {
                font-size: 1.2rem;
            }
        }
    }
}

@import '/src/ressources/styles/abstracts';

.information-text {
  p {
    font-size: 1.2rem;

    .gained-points {
      font-size: 1.5rem;
      font-weight: 500;
    }
  }

}

@import '/src/ressources/styles/abstracts';

.reward {
  display: grid !important;
  grid-template-columns: 400px 1fr auto;
  padding: 14px $small;
  height: 60px;

  .list-modal-item-right {
    display: flex;
    align-items: center;

    & > *:not(:last-child) {
      margin-right: $large !important;
    }

    .reward-amount {
      display: flex;
      align-items: center;

      i.icon {
        font-size: 2rem;
        line-height: 2rem;
        color: $secondary;
        margin-right: $small;
      }
    }

    .reward-order {
      display: flex;
      align-items: center;
    }

    .number {
      color: $primary;
      font-weight: 500;
      font-size: 1.5rem;
      display: block;
      width: 20px;
      text-align: center;
      margin-right: $small;
    }
  }
}

@import '/src/ressources/styles/abstracts';

.ranking {
    text-align: center;
    p {
        margin-top: 32px;
    }
    tr {
        td {
            border-top:1px solid rgba($gray_dark, 1);
            box-sizing: border-box;
            height: 51px;
            vertical-align: middle;
        }
    } 
    .highlighted {
        background-color: rgba(191,5,6,0.2);
    }
    .superfan {
        background: rgb(191,5,6);
background: linear-gradient(180deg, rgba(191,5,6,0.2) 0%, rgba(191,5,6,0.2) 30%, rgba(255,255,255,0.2) 30%, rgba(255,255,255,0.2) 35%, rgba(0,49,108,0.2) 35%, rgba(0,49,108,0.2) 65%, rgba(255,255,255,0.2) 65%, rgba(255,255,255,0.2) 70%, rgba(191,5,6,0.2) 70%, rgba(191,5,6,0.2) 100%);

    }
    
    i.icon.trophy {
        &.trophy-1 {
            color: #FFBF00;
        }
        &.trophy-2 {
            color: #C0C0C0;
        }
        &.trophy-3 {
            color: #CD7F32;
        }
    }
    
    
}

@import '/src/ressources/styles/abstracts';

#price-pool {
  h1 {
    margin-bottom: $medium;
  }

  h2 {
    white-space: nowrap;
  }

  .prices {
    margin-top: $medium;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: $medium;
    align-items: center;
    justify-content: center;

    .card {
      margin-bottom: $medium;
      max-width: 480px;
      width: 100%;
    }
  }
}

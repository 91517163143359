$breakpoints: (
    'tiny': (
        max-width: 320px,
    ),
    'small': (
        min-width: 375px,
    ),
    'medium': (
        min-width: 414px,
    ),
    'medium-max': (
        max-width: 414px,
    ),
    'large': (
        min-width: 428px,
    ),
    'very-large-max': (
        max-width: 540px,
    ),
    'tablet': (
        min-width: 768px,
    ),
    'tablet-max': (
        max-width: 768px,
    ),
    'desktop-small': (
        min-width: 1080px,
    ),
    'desktop-small-max': (
        max-width: 1080px,
    ),
    'desktop-large': (
        min-width: 1200px,
    ),
);

$spacing-unit: 8px;

$tiny: calc($spacing-unit / 2);   // 4px
$small: $spacing-unit;      // 8px
$medium: $spacing-unit * 2; // 16px
$large: $spacing-unit * 3;  // 24px
$huge: $spacing-unit * 6;   // 48px


$font: 'Rubik', sans-serif;

@import '/src/ressources/styles/abstracts';

.rewards {
  margin-top: $medium;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: $medium;
  align-items: center;
  justify-content: center;

  .card {
    margin-bottom: $medium;
    max-width: 480px;
    width: 100%;
  }
}

@import '/src/ressources/styles/abstracts';

#game {
    display:flex;
    flex-direction: column;
    flex-grow: 1;

    .challenge-list {
        display:flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        .card {
            max-width: 480px;
            width: 100%;
            background-position: center;
            margin:$tiny;
            margin-bottom: $small;
        }

        p {
            text-align: center;
            color: $gray_semidark;
            font-weight: 500;
            margin: $small 0;
        }
    }

    h3 {
        text-align: center;
        margin-top: 32px;
    }

    .state-anchor-links {
        display: flex;
        justify-content: center;
        align-items: center;
        margin:$small 0;

        & > a:not(:last-child) {
            margin-right: $small;
        }

        a {
            line-height: 1.2rem;
        }
    }

    #open, #closed, #resolved {
        scroll-margin-top: 50px;
    }

    .loader-wrapper {
        flex-grow: 1;
        text-align: center;
    }
}

@include respond-to('tablet') {
    .challenge-list {
        .card {
            width: calc(100% / 2 - 32px) !important;
        }
    }
}

@include respond-to('desktop-small') {
    #game {
        h1 {
            margin-bottom: $large;
        }
        .challenge-list {
            .card {
                width: calc(100% / 3 - 32px) !important;
            }
        }
        .state-anchor-links {
            display: none;
        }
    }
}

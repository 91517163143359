@import '/src/ressources/styles/abstracts';

.players {
    .player, .player-placeholder {
      padding: $small 0;
      border-bottom: 1px solid $gray_semidark;
      cursor: pointer;
    }
    .player:last-child {
        border-bottom: none;
    }
}

.loader-wrapper {
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

$primary: #a71417;
$primary_effect: #790f11;
$secondary: #1e2746;
$secondary_effect: #21337e;
$gray_light: #eeeeee;
$gray: #6c757d;
$gray_semidark: #495057;
$gray_dark: #111111;
$positive: #48742c;
$negative: #ed6660;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: $primary;
$orange: #ffa400;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;
$white: #fff;
$success: $green;
$warning: #ffc107;
$danger: $primary;
$light: #ddd;
$dark: #343a40;
$appbg: #e5e5e5;
$hoverbg: rgba($secondary_effect, 0.2);
$selectedbg: rgba($secondary_effect, 0.3);

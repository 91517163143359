.button,
button,
button.ui.button {
    padding: $tiny $medium;
    border-radius: 25px;
    color: $white;
    border: none;
    line-height: 1.8rem;
    margin:0;
    cursor: pointer;
    @include transition(background-color, 0.4s, ease);

    &,
    &.primary {
        background-color: $primary;

        &:hover,
        &:focus,
        &:active {
            background-color: $primary_effect;
            color: $white;
        }
    }

    &.secondary {
        background-color: $secondary;

        &:hover,
        &:focus,
        &:active {
            background-color: $secondary_effect;
        }
    }

    &.icon {
        i.icon {
            margin-right: 5px;
            color: $white;
            font-size: 0.9rem;
            line-height: 0.9rem;
            vertical-align: middle;
            display: inline;
        }
    }

    &:visited {
        color: $white;
    }
}

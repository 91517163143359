@import '/src/ressources/styles/abstracts';

#user {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;

    h1 {
        margin-bottom: $medium;
    }

    .user-stats {
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        .stat {
            p {
                text-align: center;
                color: $primary;
                font-size: 1.5rem;
                font-weight: 600;
            }
        }

        .primary-stats {
            margin-bottom: $medium;
        }

        .secondary-stats {
            margin-bottom: $medium;
            
            .stat {
                h3 {
                    text-align: center;
                }

                p {
                    color:$secondary_effect;
                    font-size: 1.2rem;
                }
            }
        }
    }

    .user-actions  {
        display: flex;
        flex-direction: column;
        text-align: center;

        button {
            padding: $small 0;
            margin-bottom: $small;
        }
    }
}
@import '/src/ressources/styles/abstracts';

.sign-up {
    .ui.form {
        display:flex;
        flex-direction: column;
        align-items: flex-end;

        button {
            width: 100%;
        }

        .field {
            width: 100%;
        }

        .error.message {
            width: 100%;
        }
    }
    p {
        text-align: center;
        margin: $small 0;
    }
}
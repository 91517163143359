@import '/src/ressources/styles/abstracts';

.text-placeholder {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;

    .text-placeholder-content {
        margin-left: calc(2 * $medium);
        aspect-ratio: 16 / 5;
        min-height: 64px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        flex-grow: 1;

        .text-placeholder-text {
            font-size: 1.4rem;
            color: $gray_semidark;
            font-weight: 500;
            text-align: center;
        }
    }

    & > i.icon {
        font-size: 1.5rem;
        line-height: 1.5rem;
        color:$secondary;
        margin-right: $small;
    }
}

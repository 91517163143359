@import '/src/ressources/styles/abstracts';

.sign-in {
    .sign-in-actions {
        display: flex;

        .button {
            width: 100%;
        }
    }
}
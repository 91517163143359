@import '/src/ressources/styles/abstracts';

.home-layout {
    min-height: 100vh;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-image: url('../../img/homebg.jpg');
    background-size: cover;
    background-position: center bottom;

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba($color: black, $alpha: 0.65);
        z-index: 1;
    }

    header {
        position: fixed;
        top: 0px;
        z-index: 10;
        width: 100%;
        padding: $medium;
    }

    #content {
        z-index: 10;
        margin-bottom: Max(25vh, 100px);
    }

    #footer-nav {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 10;
        width: 100%;
    }
}

@import '/src/ressources/styles/abstracts';

.redeem {
  text-align: center;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  > i.icon {
    font-size: 3rem;
    line-height: 3rem;
    margin: $medium 0 $small;
  }

  h2 {
    margin-bottom: $medium;
  }

  .redeem-slider {
    margin-top: $medium;
  }

  .code {
    font-size: 1.2rem;
    color: $gray_dark;
    font-weight: 600;
  }
}

@import '/src/ressources/styles/abstracts';

#information {
    .links {
        display: flex;
        flex-direction: column;
        align-items: center;

        & > a {
            margin-bottom: $small;
        }
    }
}